import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Article from '../components/article'
import Section from '../components/section'
import { H3 } from '../components/globals'
import { Text, Stack } from '@chakra-ui/core'

function PrivacyPolicy() {
  return (
    <Layout title="Privacy Policy">
      <SEO title="Privacy Policy" />

      <Article>
        <Section>
          <Stack spacing={6}>
            <H3>AVISO DE PRIVACIDAD</H3>
            <Text>
              La 'Operadora de Restaurantes y Hoteles de Tulum SA de CV', operadora del 'Hip Hotel
              Tulum', ubicado en la Carretera Cancún-Tulum Bocapaila Km 8, Municipio de Tulum,
              Quintana Roo, México (en adelante el 'Hotel') es responsable de la confidencialidad,
              uso y protección de su información personal. Su información personal será utilizada
              para propósitos de identificación con respecto a cualquier asunto legal o de negocios
              con el Hotel, incluyendo la compra de nuestros servicios, la promoción de nuestros
              servicios, eventos, concursos y loterías, para propósitos de mercadeo, investigación
              comercial y para evaluar la calidad de los servicios que proveemos. Para los
              propósitos arriba mencionados, podemos requerir su nombre, dirección, teléfono, correo
              electrónico, número de identificación fiscal, fecha y lugar de nacimiento, sexo,
              nacionalidad, edad, e información de crédito y propiedad. Usted tiene el derecho de
              acceder, rectificar y cancelar su información personal, así como de oponerse al uso o
              revocar su consentimiento para el uso de su información personal a través de los
              procedimientos que hemos implementado. Para estos procedimientos, requisitos y plazos,
              favor de comunicarse con nuestro Centro de Llamadas al +52 (998) 880 56 29/880 60 22
              9am - 5pm, +52 (998) 880 60 21, o vía correo electrónico a leydedatos@anayjose.com.
              Nos comprometemos a no transferir su información personal a terceros sin su
              consentimiento, excepto en los casos previstos en el artículo 37 de la Ley Federal de
              Protección de Datos Personales. En este caso la transferencia se realizará en los
              términos establecidos por la Ley. Si no se opone a la transferencia de sus datos
              personales, se considerará que ha dado su consentimiento. Si desea dejar de recibir
              mensajes promocionales de nosotros, por favor envíenos una solicitud a través de las
              opciones que se mencionan en este Aviso de Privacidad. Para cambios en este Aviso de
              Privacidad por favor visite nuestra página web www.hiphoteltulum.com
            </Text>
            <Text>
              “Operadora de Restaurantes y Hoteles de Tulum SA de CV” operator of “Ana y José”,
              located at Carretera Cancún-Tulum Bocapaila Km 8, Municipio de Tulum, Quintana Roo,
              México (hereinafter referred as the “Hotel”) is responsible of the confidentiality,
              use and protection of your personal information. Your personal information will be
              used for identification purposes regarding any legal or business matter with the
              Hotel, including, the purchase of our services, the promotion of our services, events,
              contests and lotteries, for marketing purposes, commercial research and to evaluate
              the quality of the services we provide. For the above purposes, we may require your
              name, address, phone, email, Tax ID Number, date and place of birth, sex, nationality,
              age, and credit and property information. You have the right, to access, rectify and
              cancel your personal information, as well as to oppose to the use or revoke your
              consent for the use of your personal information through the procedures that we have
              implemented. For these procedures, requirements and deadlines please contact our Call
              Center at +52 (998) 880 56 29/880 60 22 9am – 5pm, +52 (998) 880 60 21, or via email
              to leydedatos@anayjose.com We promise not to transfer your personal information to
              third parties without your consent, except as otherwise provide in Article 37 of the
              Federal Law of Protection of Personal Data. In this case the transfer will be
              performed in the terms established by Law. If you do not opposed to the transfer of
              your personal data, it will be deemed to have given your consent. If you wish to stop
              receiving promotional messages from us please send us request through the options
              mention in this Privacy Notice. For changes to this Privacy Notice please visit our
              web page www.anayjose.com
            </Text>
          </Stack>
        </Section>
      </Article>
    </Layout>
  )
}

export default PrivacyPolicy
